import axios, { AxiosInstance } from 'axios';

import { IAvailability } from '../interfaces';
import { handleAxiosError } from '../utils';

interface IAvailabilityApi {
  getAvailability(
    productId: string,
    currency: string,
    fromDate: string,
    toDate: string,
    lang: string
  ): Promise<IAvailability[] | null>;

  getNextAvailableDay(productId: string): Promise<Date | null>;
}

class AvailabilityApi implements IAvailabilityApi {
  httpClient: AxiosInstance;

  constructor() {
    const instance = axios.create({
      baseURL: process.env.GATSBY_PROXY_URL,
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    });
    this.httpClient = instance;
  }

  async getAvailability(
    productId: string,
    currency: string,
    fromDate: string,
    toDate: string,
    lang: string
  ): Promise<IAvailability[] | null> {
    try {
      const result = await this.httpClient.get(
        '/api/Availability/GetAvailability',
        {
          params: {
            productId,
            currency,
            fromDate,
            toDate,
            lang
          }
        }
      );
      return result.data;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }

  async getNextAvailableDay(productId: string): Promise<Date | null> {
    try {
      const result = await this.httpClient.get(
        '/api/Availability/GetNextAvailableDate',
        { params: { productId } }
      );
      return result.data;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }
}

export default AvailabilityApi;
