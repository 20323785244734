import React from 'react';
import * as styles from './USPBanner.module.scss';
import Icon from '../Icon';
import { Link } from '..';
import { IPageContext } from '../../interfaces';


interface Props {
  pageContext: IPageContext
}

const USPBanner: React.FunctionComponent<Props> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <USPLink
          pageContext={props.pageContext}
          iconName='USP_STAR'
          text='Leading tour operator since 1968'
          href='about-us'
          native
        />
        <USPSeparator />
        <USPLink
          pageContext={props.pageContext}
          iconName='USP_CARBON'
          text='Carbon Neutral Tours'
          href='blog/carbon-neutral-iceland-tours'
          native
        />
        <USPSeparator />
        <USPLink
          pageContext={props.pageContext}
          iconName='USP_SHOOTING_STAR'
          text='Certified and experienced guides'
          notClickable
        />
        <USPSeparator />
        <USPLink
          pageContext={props.pageContext}
          iconName='USP_SHIELD'
          text='Easy booking & cancellation'
          href='terms-and-conditions'
          native
        />
        <USPSeparator />
        <USPLink
          pageContext={props.pageContext}
          iconName='USP_TRIPADVISOR'
          text='Tripadvisor Travelers’ Choice'
          href='https://www.tripadvisor.com/Attraction_Review-g189970-d1556635-Reviews-Reykjavik_Excursions-Reykjavik_Capital_Region.html'
          newTab
        />
      </div>
    </div>
  );
};

interface IUSPLinkProps {
  pageContext: IPageContext;
  iconName: string;
  text: string;
  href?: string;
  newTab?: boolean;
  native?: boolean;
  notClickable?: boolean;
}

const USPLink: React.FunctionComponent<IUSPLinkProps> = (props) => {
  if (props.notClickable) {
    return (
      <div className={styles.linkNotClickable}>
        <Icon name={props.iconName} />
        <p className={styles.linkText}>{props.text}</p>
      </div>
    );
  }

  if (props.native) {
    return (
      <Link to={props.href || ''} className={styles.link} langPath={props.pageContext.langPath}>
        <Icon name={props.iconName} />
        <p className={styles.linkText}>{props.text}</p>
      </Link>
    )
  }

  return (
    <a href={props.href}
      target={props.newTab ? '_blank' : ''}
      rel='noopener noreferrer'
      className={styles.link}
    >
      <Icon name={props.iconName} />
      <p className={styles.linkText}>{props.text}</p>
    </a>
  );
}

const USPSeparator: React.FunctionComponent = () => {
  return (
    <div className={styles.separator} />
  );
}

export default USPBanner;
