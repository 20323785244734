import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

import { IReducers, ISEO } from '../../interfaces';
// import { favicon16, favicon32, favicon } from '../../icons';

interface IProps {
  seo: ISEO;
  langKey: string;
  title?: string;
  isIcelandair: boolean;
  langSlugs: { [locale: string]: string };
}

interface IMeta {
  name?: string;
  property?: string;
  content?: string | string[];
}

const Meta: React.FunctionComponent<IProps> = ({
  seo,
  langKey,
  title,
  isIcelandair,
  langSlugs,
}) => {

  const {
    metaTitle,
    metaDescription,
    metaKeywords,
    metaRobots,
    ogimages,
    ogtitle,
    ogtype,
    faqPage
  } = seo;

  const ogImages = ogimages
    ? ogimages.map((img) => ({
      property: 'og:image',
      content: img.fluid?.src ?? '',
    }))
    : [];

  const metaTags = [
    ...ogImages,
    {
      name: 'keywords',
      content: metaKeywords,
    },
    {
      name: 'description',
      content: metaDescription,
    },
    {
      name: 'robots',
      content: metaRobots,
    },
    {
      property: 'og:title',
      content: ogtitle,
    },
    {
      property: 'og:type',
      content: ogtype,
    },
    {
      'http-equiv': 'Content-Security-Policy',
      content: 'upgrade-insecure-requests',
    },
    {
      name: 'facebook-domain-verification',
      content: 'k4yw4anukpaokuh1h9cd341fpsx6id',
    },
    // Test fyrir google verification
    {
      name: 'google-site-verification',
      content: 'v7DzhmaGMeVZl0meLMUOzwF3P-I6w3IMWtTGytd8ULQ',
    },
  ].filter((n: IMeta) => {
    return n.content !== null;
  });

  /*

<!-- Google Tag Manager -->

<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':

new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],

j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=

'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);

})(window,document,'script','dataLayer','GTM-N43CW8L');</script>

<!-- End Google Tag Manager -->

*/
  if (isIcelandair) return null;

  const siteConfig = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const { siteUrl } = siteConfig.site.siteMetadata;

  return (
    <Helmet
      title={metaTitle || title || 'Reykjavik Excursions'}
      meta={metaTags}
    >
      <html lang={langKey} />

      {!!langSlugs
        ? Object.keys(langSlugs).map((l) => (
          <link
            rel='alternate'
            hrefLang={l}
            href={siteUrl + '/' + langSlugs[l].replace(/^\//, '')}
          />
        ))
        : null}

      {/* <!-- Canonical Tag --> */}
      {(langKey === 'is' && !langSlugs['en-US'].includes('flybus')) && <link rel='canonical' href={`${siteUrl}/${langSlugs['en-US']}`} />}

      {/* Anti Flicker */}
      <style>{`<style>.async-hide { opacity: 0 !important} </style>`}</style>
      {/** cookiebot script */}
      <script
        id='Cookiebot'
        src='https://consent.cookiebot.com/uc.js'
        data-cbid='e7996fce-01be-4b3e-a198-e1f544240660'
        data-blockingmode='auto'
        type='text/javascript'
      />
      <script type='text/javascript' data-cookieconsent='marketing'>
        {`function CookiebotCallback_OnAccept() {
        adroll_adv_id = "FXFG4XSI4VDODHNCU37QZH";
        adroll_pix_id = "WZQEJU4EGFFKTJGTUUHBRK";
          if (Cookiebot.consent.marketing) {
            (function () {

              if (!window.__adroll_loaded && Cookiebot.consent.marketing) {
              var scr = document.createElement("script");
              var host = (("https:" == document.location.protocol) ? "https://s.adroll.com" : "http://a.adroll.com");
              scr.setAttribute('async', 'true');
              scr.type = "text/javascript";
              scr.src = host + "/j/roundtrip.js";
              ((document.getElementsByTagName('head') || [null])[0] ||
                  document.getElementsByTagName('script')[0].parentNode).appendChild(scr);
          };
          }());
          }

      }`}
      </script>
      {/*beacon */}
      <script type='text/javascript'>{`!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`}</script>
      <script type='text/javascript'>{`window.Beacon('init', '8af9c921-8fb8-4777-a767-8dea68f9b72f')`}</script>

      {/* MAILCHIMP VERIFICATION*/}
      <script id='mcjs-head'>{`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/e3270922057538e5391cf8999/76b5be698c1119ae50015c6db.js");`}</script>
      {/* Aggregate rating*/}
      <script type='application/ld+json'>
        {`{
        "@context": "https://schema.org",
        "@type": "TravelAgency",
        "name": "Reykjavík Excursion",
        "image": "https://images.ctfassets.net/a68ipajj4t9l/5DSYqVk16tGGkOGHRUiCxq/4462b9c91dd709cb7a297de719a4a103/Flybus_Lineup-progressive.jpg?w=2400&q=60",
        "@id": "https://www.re.is/",
        "url": "https://www.re.is/",
        "telephone": "+354 580 54 00",
        "aggregateRating": {
        "@type": "AggregateRating",
          "ratingValue": "4.3",
          "reviewCount": "2010",
          "bestRating": "5",
          "worstRating": "1",
          "url": "https://www.facebook.com/reykjavikexcursions/reviews/"
                        },
        "priceRange": "25 - 2080",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Vatnsmýrarvegur 10",
          "addressLocality": "Reykjavik",
          "postalCode": "101",
          "addressCountry": "IS"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": 64.1287287,
          "longitude": 21.943098
        },
        "openingHoursSpecification": {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
          ],
          "opens": "04:00",
          "closes": "22:00"
        },
        "sameAs": [
          "https://www.facebook.com/reykjavikexcursions",
          "https://twitter.com/rvkexcursions",
          "https://www.instagram.com/reykjavikexcursions/"
        ]
  }`}</script>

      {(faqPage && faqPage.length > 0) && (
        <script type="application/ld+json">
          {`{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity":  [${faqPage.map((faq, idx) =>
            `{
              "@type": "Question",
              "name": "${faq.question}",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "${faq.answer.answer}"
              }
            }${faqPage.length - 1 > idx ? '\n' : ''}`
          )}]
          }`}
        </script>
      )}
    </Helmet>
  );
};
const mapStateToProps = (reducers: IReducers) => {
  const { isIcelandair } = reducers.layoutReducer;
  return { isIcelandair };
};
export default connect(mapStateToProps)(Meta);
