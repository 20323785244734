
import React from 'react';

// arrow right
export const USPTripadvisor = () => (
  <svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.2836 3.78634L21 1.98595H17.1937C15.2882 0.730896 12.987 0 10.4984 0C8.0128 0 5.71794 0.732542 3.81566 1.98595H0L1.71624 3.78634C0.664177 4.71184 0.00469386 6.04507 0.00469386 7.52517C0.00469386 10.3185 2.35353 12.5832 5.25069 12.5832C6.62705 12.5832 7.88073 12.0714 8.81673 11.2348L10.4982 13L12.1796 11.2364C13.1156 12.0731 14.368 12.5832 15.7442 12.5832C18.6413 12.5832 20.9934 10.3185 20.9934 7.52517C20.9949 6.04342 20.3354 4.7104 19.2833 3.78634H19.2836ZM5.25219 10.9482C3.29102 10.9482 1.70194 9.41608 1.70194 7.52517C1.70194 5.63426 3.29123 4.10211 5.25219 4.10211C7.21336 4.10211 8.80243 5.63446 8.80243 7.52517C8.80243 9.41587 7.21314 10.9482 5.25219 10.9482ZM10.4999 7.4256C10.4999 5.17305 8.80094 3.23935 6.55857 2.41342C7.77128 1.92465 9.10134 1.65352 10.4984 1.65352C11.8952 1.65352 13.227 1.92465 14.4397 2.41342C12.1991 3.241 10.5001 5.17305 10.5001 7.4256H10.4999ZM15.7459 10.9482C13.7847 10.9482 12.1956 9.41608 12.1956 7.52517C12.1956 5.63426 13.7849 4.10211 15.7459 4.10211C17.7071 4.10211 19.2961 5.63446 19.2961 7.52517C19.2961 9.41587 17.7069 10.9482 15.7459 10.9482ZM15.7459 5.7293C14.7175 5.7293 13.8848 6.53219 13.8848 7.52373C13.8848 8.51506 14.7175 9.31795 15.7459 9.31795C16.7741 9.31795 17.6068 8.51506 17.6068 7.52373C17.6068 6.53384 16.7741 5.7293 15.7459 5.7293ZM7.11329 7.52517C7.11329 8.5165 6.28056 9.31939 5.25219 9.31939C4.22402 9.31939 3.39129 8.5165 3.39129 7.52517C3.39129 6.53363 4.22402 5.73074 5.25219 5.73074C6.28035 5.7293 7.11329 6.53363 7.11329 7.52517Z" fill="white" />
  </svg>
);
export default USPTripadvisor;
