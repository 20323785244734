import React from 'react';
import { StaticQuery, graphql, navigate } from 'gatsby';
import { currencies, filterParams } from '../../constants';
import { CartApi } from '../../api';
import {
  IPageContext,
  IHeaderList,
  IHeader,
  IFilterSelectItem,
  IReducers,
  ICart,
  ICurrencyOption,
  IContentfulHeader,
  IContentfulHeaderObject,
} from '../../interfaces';
import { Desktop, Mobile } from '..';
import 'rc-drawer/assets/index.css';
import ls from 'local-storage';
import StickyHeader from '../StickyHeader';
import { connect } from 'react-redux';
import { changeCurrency } from '../../redux/actions/currencyActions';
// import { changeLocale } from '../../redux/actions/translationActions';
import { getCurrencyConstant, formatSearch } from '../../utils';
import HeaderMobile from '../HeaderMobile';
import HeaderDrawer from '../HeaderDrawer';
import moment from 'moment';
import { changeDismiss } from '../../../src/redux/actions/layoutActions';
import { USPBanner } from '../';

interface IExternalProps {
  pageContext: IPageContext;
  currency: ICurrencyOption;
  cartObject: ICart | null;
  pathname: string;
  changeCurrency(newCurrency: ICurrencyOption, cartCount: number): void;
  // changeLocale(newLocale: string): void;
  locale: string;
  icelandairUrl: boolean;
  isIcelandair: boolean;
  isUserFlybus: boolean;
  changeDismiss(parameter: boolean): void;
}

interface IProps extends IExternalProps {
  data: {
    allContentfulHeader: IHeaderList;
  };
  localeData: IContentfulHeader;
}

interface IOption {
  label: string;
  code: string;
  value: string;
  icon: string;
  lightIcon: string;
}

interface IState {
  open: boolean;
  openCart: boolean;
  dropdownOpen: boolean;
  currencyOptions: IFilterSelectItem[];
  selectedCurrency: string;
  options: IOption[];
  searchOpen: boolean;
  searchValue: string;
  drawerLinkClicked: string;
  queryIcelandair: string;
  isIcelandairQuery: boolean;
  announcement: IContentfulHeaderObject | undefined;
}
class Header extends React.Component<IProps, IState> {
  cartApi = new CartApi();
  readonly state: IState = {
    open: false,
    openCart: false,
    dropdownOpen: false,
    currencyOptions: [],
    selectedCurrency: '',
    options: [],
    searchOpen: false,
    searchValue: '',
    drawerLinkClicked: '',
    queryIcelandair: '',
    isIcelandairQuery: false,
    announcement: undefined,
  };

  search: HTMLDivElement | null = null;
  input: HTMLDivElement | null = null;

  componentDidMount() {
    this.getCurrencyOptions();

    const options: IOption[] = [];
    Object.keys(currencies).map((key) => {
      const localCurrency = getCurrencyConstant(key);
      options.push(localCurrency);
    });
    this.setState(
      { options, selectedCurrency: this.props.currency.code },
      () => {
        this.getLocalization();
      }
    );
    document.addEventListener('mousedown', this.handleClickOutsideSearch);
    this.setState({ announcement: this.props.localeData.announcement });
  }

  componentDidUpdate(_P: IProps, prevState: IState) {
    if (this.state.selectedCurrency !== prevState.selectedCurrency) {
      this.getCurrencyOptions();
      this.getLocalization();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideSearch);
  }

  setOpenAndCart = () => {
    this.setState({
      open: false,
      openCart: !this.state.openCart,
    });
  };

  onchange = () => {
    const temp = !this.state.open;
    this.setState({ open: temp });
    if (temp) {
      this.setState({ openCart: false });
    }
  };

  toggleDropdownOpen = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  selectCurrency = (index: number) => {
    const { cartObject } = this.props;
    // TODO refactor this
    if (!!cartObject && !!cartObject.Items ? cartObject.Items.length : 0) {
      // empty
    } else {
      this.setState({
        selectedCurrency: this.state.options[index].code,
        dropdownOpen: false,
      });
    }
    this.props.changeCurrency(
      this.state.options[index] as IOption,
      !!cartObject && !!cartObject.Items ? cartObject.Items.length : 0
    );
  };

  getLocalization = async () => {
    let optionNumber = 0;
    const geoLocation = await this.cartApi.getLocalizationData();
    if (!!this.state.options && !!geoLocation) {
      if (geoLocation.country === 'IS') {
        optionNumber = 0;
      } else {
        if (
          geoLocation.continent_code === 'EU' &&
          geoLocation.currency !== 'GBP'
        ) {
          optionNumber = 1;
        } else if (geoLocation.country_name === 'United States') {
          optionNumber = 2;
        } else if (
          geoLocation.continent_code === 'EU' &&
          geoLocation.currency === 'GBP'
        ) {
          optionNumber = 3;
        } else {
          optionNumber = 2;
        }
      }
      const localCurrency = ls.get('currency');
      if (localCurrency === null) {
        this.props.changeCurrency(this.state.options[optionNumber] as any, 0);
      }
    }

    // NOTE this is added here for testing purposes, will create a seperate function and a way to handle direct links to other language sites
  };

  getCurrencyOptions = () => {
    const { selectedCurrency } = this.state;
    const newCurrecyOtions = Object.keys(currencies).map((key) => {
      return {
        title: currencies[key].label,
        selected: key === selectedCurrency ? true : false,
      };
    });
    this.setState({ currencyOptions: newCurrecyOtions });
  };

  expiredAnnouncement = () => {
    const { announcement } = this.state;
    if (announcement?.unpublishDate) {
      const expireDate = moment(announcement.unpublishDate);
      const currentDate = moment(new Date());

      if (currentDate <= expireDate) {
        return false;
      } else {
        changeDismiss(true);
      }
    }
    return true;
  };

  cartChange = (open: boolean) => {
    this.setState({ openCart: open });
    if (open) {
      this.setState({ open: false });
    }
  };

  updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value });
  };

  onSearchClick = () => {
    const { searchOpen, searchValue } = this.state;
    if (searchOpen) {
      if (searchValue === '') {
        this.setState({ searchOpen: false });
      } else {
        this.performSearch();
      }
    } else {
      this.setState({ searchOpen: true }, () => {
        if (this.input) {
          this.input.focus();
        }
      });
    }
  };

  handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.performSearch();
      this.setState({ open: false, openCart: false });
    }
  };

  handleClickOutsideSearch = (e: any) => {
    const { searchOpen, searchValue } = this.state;
    if (
      this.search &&
      !this.search.contains(e.target) &&
      searchOpen &&
      searchValue === ''
    ) {
      this.setState({ searchOpen: false });
    }
  };

  performSearch = () => {
    const { searchValue } = this.state;
    const { langPath, locale } = this.props.pageContext;
    const { edges } = this.props.data.allContentfulHeader;
    let headerData: IHeader | null = null;

    for (const edge of edges) {
      if (edge.node.node_locale === locale) {
        headerData = edge.node;
      }
    }

    if (headerData && searchValue !== '') {
      this.setState({ searchOpen: false, searchValue: '' });
      navigate(
        `/${langPath}${headerData.searchLink.slug}/?${
        filterParams.search
        }=${formatSearch(searchValue)}`
      );
    }
  };
  getQueryIcelandair = () => {
    const vars = window.location.search.substring(
      0,
      window.location.search.length
    );
    return vars != '' ? vars : '';
  };

  setDrawerLinksClicked = (randomString: string) => {
    this.setState({ drawerLinkClicked: randomString });
  };

  render() {
    const { searchOpen, searchValue, queryIcelandair } = this.state;
    const { langPath } = this.props.pageContext;
    const { localeData, data, icelandairUrl } = this.props;
    const { announcement } = localeData;

    return (
      <div>
        <Desktop>
          <StickyHeader
            langPath={this.props.pageContext.langPath}
            contentfulHeader={this.props.data.allContentfulHeader}
            pageContext={this.props.pageContext}
            pathname={this.props.pathname}
            open={this.state.open}
            setOpen={this.cartChange}
            searchValue={this.state.searchValue}
            updateSearch={this.updateSearch}
            handleEnter={this.handleEnter}
            onSearchClick={this.onSearchClick}
            contentfulData={data}
            announcement={announcement}
            isIcelandairUrl={icelandairUrl}
            queryIcelandair={queryIcelandair}
            expiredAnnouncement={this.expiredAnnouncement}
          />
          <USPBanner pageContext={this.props.pageContext} />
        </Desktop>
        <Mobile>
          <HeaderMobile
            langPath={langPath}
            contentfulData={data}
            open={this.state.open}
            searchValue={searchValue}
            searchOpen={searchOpen}
            openCart={this.state.openCart}
            updateSearch={this.updateSearch}
            handleEnter={this.handleEnter}
            onSearchClick={this.onSearchClick}
            cartChange={this.cartChange}
            onchange={() => this.onchange()}
            setOpenAndCart={this.setOpenAndCart}
            announcement={announcement}
            drawerLinkClicked={this.state.drawerLinkClicked}
            isIcelandairUrl={icelandairUrl}
            expiredAnnouncement={this.expiredAnnouncement}
          />
        </Mobile>
        <HeaderDrawer
          open={this.state.open}
          searchOpen={searchOpen}
          pageContext={this.props.pageContext}
          contentfulData={data}
          pathname={this.props.pathname}
          cartChange={this.cartChange}
          currencyOptions={this.state.currencyOptions}
          currency={this.props.currency}
          dropdownOpen={this.state.dropdownOpen}
          toggleDropdownOpen={this.toggleDropdownOpen}
          selectCurrency={this.selectCurrency}
          setDrawerLinksClicked={this.setDrawerLinksClicked}
          langPath={langPath}
          isIcelandairUrl={icelandairUrl}
          queryIcelandair={queryIcelandair}
        />
      </div>
    );
  }
}

const mapStateToProps = (reducers: IReducers) => {
  const { currency } = reducers.currencyReducer;
  const { cartObject } = reducers.cartReducers;
  const { locale } = reducers.translationReducers;
  const { isIcelandair } = reducers.layoutReducer;
  return { currency, cartObject, locale, isIcelandair };
};

export default connect(mapStateToProps, { changeCurrency })(
  ({
    pageContext,
    currency,
    changeCurrency: currencyChanger,
    cartObject,
    pathname,
    locale,
    icelandairUrl,
    isIcelandair,
    isUserFlybus,
    changeDismiss,
  }: IExternalProps) => (
      <StaticQuery
        query={graphql`
        query HeaderQuery {
          allContentfulHeader {
            ...HeaderList
          }
          allContentfulTour {
            edges {
              node {
                productId
                slug
              }
            }
          }
          allContentfulHeader {
            edges {
              node {
                announcement {
                  announcement {
                    announcement
                  }
                  announcementMobile {
                    announcementMobile
                  }
                  unpublishDate
                }
              }
            }
          }
        }
      `}
        render={(data) => (
          <Header
            localeData={
              data.allContentfulHeader.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            data={data}
            pageContext={pageContext}
            changeCurrency={currencyChanger}
            currency={currency}
            cartObject={cartObject}
            pathname={pathname}
            locale={locale}
            icelandairUrl={icelandairUrl}
            isIcelandair={isIcelandair}
            isUserFlybus={isUserFlybus}
            changeDismiss={changeDismiss}
          />
        )}
      />
    )
);
