// extracted by mini-css-extract-plugin
export var hero = "TourHero-module--hero--1rVOf";
export var image = "TourHero-module--image--2QZ7M";
export var imageMobile = "TourHero-module--imageMobile--1KOtf";
export var right = "TourHero-module--right--32dI0";
export var darker = "TourHero-module--darker--Ymyhr";
export var content = "TourHero-module--content--2f8HD";
export var titleContainer = "TourHero-module--titleContainer--2qF3s";
export var line = "TourHero-module--line--1HuEc";
export var titleContainerMobile = "TourHero-module--titleContainerMobile--2hC6U";
export var titleContainerAddToCart = "TourHero-module--titleContainerAddToCart--Ba3Mt";
export var infoContainer = "TourHero-module--infoContainer--3bDEk";
export var rating = "TourHero-module--rating--3MFkn";
export var offerBanner = "TourHero-module--offerBanner--3x0o3";
export var ratingInfoWrapper = "TourHero-module--ratingInfoWrapper--2JMuG";
export var ratingInfo = "TourHero-module--ratingInfo--AH3b0";
export var info = "TourHero-module--info--1VLFR";
export var infoItem = "TourHero-module--infoItem--3Ozg9";
export var infoItem2 = "TourHero-module--infoItem2--1ob49";
export var infoItem3 = "TourHero-module--infoItem3--3YOrJ";
export var audioGuideContainer = "TourHero-module--audioGuideContainer--15irS";
export var infoItem3Mobile = "TourHero-module--infoItem3Mobile--ZoN4e";
export var infoItemFlag = "TourHero-module--infoItemFlag--1oBZv";
export var infoItemFlagMobile = "TourHero-module--infoItemFlagMobile--2GFiY";
export var loading = "TourHero-module--loading--gPYmj";
export var bookNav = "TourHero-module--bookNav--12YFn";
export var itemContainer = "TourHero-module--itemContainer--1gkTL";
export var mobileItem = "TourHero-module--mobileItem--rt2N_";
export var languageMobileItem = "TourHero-module--languageMobileItem--6ldRA";
export var addToCartButton = "TourHero-module--addToCartButton--9eGOZ";
export var exit = "TourHero-module--exit--1vYEp";
export var exitContainer = "TourHero-module--exitContainer--2Z4gG";
export var hideNav = "TourHero-module--hideNav--X5NmN";
export var price = "TourHero-module--price--208uu";
export var originalPrice = "TourHero-module--originalPrice--2whcj";
export var offerPrice = "TourHero-module--offerPrice--2sYbx";
export var column = "TourHero-module--column--rl3d5";
export var columnTEST = "TourHero-module--columnTEST--17U6w";
export var row = "TourHero-module--row--3ulwR";
export var rowRight = "TourHero-module--rowRight--2RhtD";
export var rowPrice = "TourHero-module--rowPrice--2rmKM";
export var banner = "TourHero-module--banner--3pyEr";
export var ghostMobile = "TourHero-module--ghostMobile--3KGrk";
export var carbonLogoDesktop = "TourHero-module--carbonLogoDesktop--1B6u6";
export var carbonLogoMobile = "TourHero-module--carbonLogoMobile--32Rk_";
export var tourId = "TourHero-module--tourId--33WQM";